"use client";

import Image from "next/image";
import cn from "classnames";
import React, { useState } from "react";

import type { LayoutType } from "../../utils/layout";
import type { Url } from "../../types/content";

import styles from "./Video.module.scss";
import {
  getImageSizes,
  getThumbnailUrl,
} from "../ArticleContent/resources/Url/utils";
import { Caption } from "./Caption/Caption";

const sizes = getImageSizes({ web: "668px", tablet: "100vw" });

const getVideoId = (video: Url) => {
  return video!.data!.custom_properties!.video_id;
};

const Video = ({
  resource,
  layoutType,
  isGrouped,
  isFirst = false,
  isMainResource = false,
  hasPadding = true,
  thumbnailContainerClassName,
}: {
  resource: Url;
  layoutType?: LayoutType;
  isGrouped?: boolean;
  isFirst?: boolean;
  isMainResource?: boolean;
  hasPadding?: boolean;
  thumbnailContainerClassName?: string;
}) => {
  const videoId = getVideoId(resource);
  const [played, setPlayed] = useState(false);
  const originUrl = "";

  const thumbnailUrl =
    resource.data?.image || getThumbnailUrl(resource, "large");
  const isNewsArticle = layoutType === "news";

  const play = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setPlayed(true);
  };

  if (!played) {
    return (
      <figure
        className={cn([
          styles.thumbnailContainer,
          isNewsArticle && styles.news,
          layoutType && styles[layoutType],
          isFirst && styles.first,
          isGrouped && styles.grouped,
          isMainResource && styles.mainVideo,
          !hasPadding && styles.noPadding,
          thumbnailContainerClassName && thumbnailContainerClassName,
        ])}
      >
        <Image
          src={thumbnailUrl}
          alt=""
          className={styles.thumbnailImage}
          data-testid="videoThumbnail"
          onClick={play}
          onKeyDown={play}
          sizes={sizes}
          width={0}
          height={0}
          priority={isNewsArticle}
        />
        <button
          className={styles.imageButton}
          onClick={play}
          onKeyDown={play}
          type="button"
          aria-label="Spela videon"
        >
          <Image
            data-testid="videoPlayButton"
            alt=""
            height={60}
            width={60}
            src={`${process.env.NEXT_PUBLIC_ASSET_PREFIX || ""}/images/playbtn.png`}
          />
        </button>
        {isMainResource ? (
          <figcaption
            className={cn([
              styles.captionContainer,
              styles.captionOnHover,
              isGrouped && styles.grouped,
              layoutType && styles[layoutType],
            ])}
          >
            {resource.title?.value && <span>{resource.title?.value}</span>}
          </figcaption>
        ) : (
          <Caption
            text={resource.title?.value}
            show={resource.show_caption}
            classNameOverride={cn(
              styles.caption,
              isGrouped && styles.grouped,
              layoutType && styles[layoutType],
              isFirst && styles.first,
            )}
          />
        )}
      </figure>
    );
  }

  return (
    <div
      className={cn([
        styles.videoPlayer,
        layoutType && styles[layoutType],
        isFirst && styles.first,
        isMainResource && styles.mainVideo,
        thumbnailContainerClassName && thumbnailContainerClassName,
      ])}
    >
      <iframe
        data-video-id={videoId}
        allow="autoplay"
        data-testid="videoIframe"
        allowFullScreen
        title={videoId}
        src={`https://www.youtube.com/embed/${videoId}?rel=0&amp;autoplay=1&enablejsapi=1&amp;origin=${originUrl}`}
      />
      <Caption
        text={resource.title?.value}
        show={resource.show_caption}
        classNameOverride={cn(
          styles.caption,
          isGrouped && styles.grouped,
          layoutType && styles[layoutType],
        )}
      />
    </div>
  );
};

export { Video };
