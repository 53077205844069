import type { ReadonlyURLSearchParams } from "next/navigation";
import type { PageType } from "@schibsted/pulse-news-media";

import { isNewsMixPage } from "../../../utils/page";
import { updateObjectPage, updateOrigin } from "../../../pulse";
import { trackViewNewsMixPage } from "../../../pulse/event/trackViewNewsMixPage";

type IsViewNewsMixCheck = {
  pathname: string;
  type: PageType;
  runOnce: boolean;
};

type ViewNewsMix = {
  type: PageType;
  url: string;
  runOnce: boolean;
  searchParams: ReadonlyURLSearchParams;
};
const shouldViewNewsMix = ({ pathname, type, runOnce }: IsViewNewsMixCheck) =>
  type === "Page" && isNewsMixPage(pathname) && runOnce;

const viewNewsMix = ({ type, url, searchParams }: ViewNewsMix) => {
  const newsMixPageId = "sdrn:omnise:page:newsMixSettingsPage";
  const newsMixPageName = "Newsmix Settings Page";

  updateObjectPage({
    id: newsMixPageId,
    type,
    url,
    name: newsMixPageName,
  });

  trackViewNewsMixPage(newsMixPageId, newsMixPageName);

  updateOrigin({
    type,
    url,
    source: searchParams.get("utm_source") || undefined,
    channel:
      searchParams.get("utm_medium") ||
      searchParams.get("utm_channel") ||
      undefined,
    content: searchParams.get("utm_content") || undefined,
    campaign: searchParams.get("utm_campaign") || undefined,
    terms: searchParams.get("utm_terms") || undefined,
  });
};

export { shouldViewNewsMix, viewNewsMix };
