import type { ReadonlyURLSearchParams } from "next/navigation";
import type { PageType } from "@schibsted/pulse-news-media";

import { isCategoryPage } from "../../../utils/page";
import { updateObjectPage, updateOrigin } from "../../../pulse";
import { trackViewListPage } from "../../../pulse/event/trackListingPage";

type IsViewCategoryCheck = {
  type: PageType;
  pathname: string;
  id?: string;
  runOnce: boolean;
};

type ViewCategory = {
  url: string;
  id?: string;
  type: PageType;
  searchParams: ReadonlyURLSearchParams;
};

const shouldViewCategoryEvent = ({
  type,
  pathname,
  id,
  runOnce,
}: IsViewCategoryCheck) =>
  type === "Listing" && isCategoryPage(pathname, id) && runOnce;

const viewCategory = ({ url, id, type, searchParams }: ViewCategory) => {
  if (id) {
    const name = url.split("/").pop();
    updateObjectPage({
      id,
      type,
      url,
      name,
    });

    trackViewListPage(id!, name!);

    updateOrigin({
      id,
      type,
      url,
      name,
      source: searchParams.get("utm_source") || undefined,
      channel:
        searchParams.get("utm_medium") ||
        searchParams.get("utm_channel") ||
        undefined,
      content: searchParams.get("utm_content") || undefined,
      campaign: searchParams.get("utm_campaign") || undefined,
      terms: searchParams.get("utm_terms") || undefined,
    });
  }
};

export { shouldViewCategoryEvent, viewCategory };
