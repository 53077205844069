import type { PageType } from "@schibsted/pulse-news-media";
import type { ReadonlyURLSearchParams } from "next/navigation";

import { isSearchPage } from "../../../utils/page";
import { updateObjectPage, updateOrigin } from "../../../pulse";
import { trackViewListPage } from "../../../pulse/event/trackListingPage";

type IsViewSearchCheck = {
  type: PageType;
  id?: string;
  runOnce: boolean;
};

type ViewSearch = {
  url: string;
  id?: string;
  type: PageType;
  searchParams: ReadonlyURLSearchParams;
};

const shouldViewSearchEvent = ({ type, id, runOnce }: IsViewSearchCheck) =>
  type === "Listing" && isSearchPage(id) && runOnce;

const viewSearch = ({ id, type, url, searchParams }: ViewSearch) => {
  if (id) {
    const name = "Search";
    updateObjectPage({
      id,
      type,
      url,
      name,
    });

    trackViewListPage(id, name);

    updateOrigin({
      id: id,
      type,
      url,
      name,
      source: searchParams.get("utm_source") || undefined,
      channel:
        searchParams.get("utm_medium") ||
        searchParams.get("utm_channel") ||
        undefined,
      content: searchParams.get("utm_content") || undefined,
      campaign: searchParams.get("utm_campaign") || undefined,
      terms: searchParams.get("utm_terms") || undefined,
    });
  }
};

export { shouldViewSearchEvent, viewSearch };
