import cn from "classnames";

import styles from "./Timestamp.module.scss";
import { FromDateTime } from "./FromDateTime/FromDateTime";

const Timestamp = ({
  dateTime,
  showRelativeTime = false,
  className,
}: {
  dateTime?: string;
  showRelativeTime?: boolean;
  className?: string;
}) => {
  if (!dateTime) return null;
  return (
    <time
      className={cn([!className && styles.timestamp, className])}
      suppressHydrationWarning={true}
      dateTime={dateTime}
    >
      <FromDateTime showRelativeTime={showRelativeTime} dateTime={dateTime} />
    </time>
  );
};

export { Timestamp };
