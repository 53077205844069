import type { PageType } from "@schibsted/pulse-news-media";
import type { Article } from "../../../types/content";
import type { ReadingContext } from "../../../types/pulse";
import type { ReadonlyURLSearchParams } from "next/navigation";

import { updateObjectPage, updateOrigin } from "../../../pulse";
import { trackViewArticle } from "../../../pulse/event/trackViewArticle";
import { isArticlePage } from "../../../utils/page";

type ViewArticle = {
  isPremiumUser?: boolean;
  article?: Article;
  readingContext?: ReadingContext;
  type: PageType;
  url: string;
  searchParams: ReadonlyURLSearchParams;
};

type IsViewArticleCheck = {
  article?: Article;
  readingContext?: ReadingContext;
  type: PageType;
  pathname: string;
  runOnce: boolean;
};

const isOmniEconomy = process.env.NEXT_PUBLIC_UI_THEME === "ekonomi";

const shouldViewArticleEvent = ({
  type,
  article,
  readingContext,
  pathname,
  runOnce,
}: IsViewArticleCheck) =>
  type === "Article" &&
  article &&
  readingContext &&
  isArticlePage(pathname) &&
  article.article_id === pathname.split("/").at(-1) &&
  runOnce;

const viewArticle = ({
  article,
  isPremiumUser,
  readingContext,
  type,
  url,
  searchParams,
}: ViewArticle) => {
  if (article && typeof isPremiumUser !== "undefined" && readingContext) {
    updateObjectPage({
      id: article.article_id,
      type,
      url,
      name: article.title.value,
    });

    // in case article is premium and user is not, we use PulseTrackSalesPosterImpression
    const shouldTrackArticleView =
      (!isOmniEconomy && !article?.is_premium) ||
      (isOmniEconomy && isPremiumUser) ||
      (article?.is_premium && isPremiumUser);

    if (shouldTrackArticleView) {
      trackViewArticle(article, readingContext, url);

      updateOrigin({
        id: article.article_id,
        type,
        url,
        name: article.title.value,
        source: searchParams.get("utm_source") || undefined,
        channel:
          searchParams.get("utm_medium") ||
          searchParams.get("utm_channel") ||
          undefined,
        content: searchParams.get("utm_content") || undefined,
        campaign: searchParams.get("utm_campaign") || undefined,
        terms: searchParams.get("utm_terms") || undefined,
      });
    }
  }
};

export { viewArticle, shouldViewArticleEvent };
