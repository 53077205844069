import type { PageType } from "@schibsted/pulse-news-media";
import type { ReadonlyURLSearchParams } from "next/navigation";

import { isFrontPage } from "../../../utils/page";
import { updateObjectPage, updateOrigin } from "../../../pulse";
import { trackViewFrontpage } from "../../../pulse/event/trackViewFrontpage";

type ViewFrontPage = {
  id?: string;
  url: string;
  type: PageType;
  searchParams: ReadonlyURLSearchParams;
};

type IsViewFrontPageCheck = {
  type: PageType;
  id?: string;
  runOnce: boolean;
  pathname: string;
};

const shouldViewFrontPage = ({
  type,
  id,
  pathname,
  runOnce,
}: IsViewFrontPageCheck) =>
  type === "Frontpage" && id && isFrontPage(pathname) && runOnce;

const viewFrontPage = ({ id, type, url, searchParams }: ViewFrontPage) => {
  if (id) {
    updateObjectPage({
      id,
      type,
      url,
      name: id,
    });

    trackViewFrontpage(id);

    updateOrigin({
      id,
      type,
      url: url,
      name: id,
      source: searchParams.get("utm_source") || undefined,
      channel:
        searchParams.get("utm_medium") ||
        searchParams.get("utm_channel") ||
        undefined,
      content: searchParams.get("utm_content") || undefined,
      campaign: searchParams.get("utm_campaign") || undefined,
      terms: searchParams.get("utm_terms") || undefined,
    });
  }
};

export { shouldViewFrontPage, viewFrontPage };
