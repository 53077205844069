import type { PageType } from "@schibsted/pulse-news-media";
import type { ReadonlyURLSearchParams } from "next/navigation";

import { isTopicPage } from "../../../utils/page";
import { getTitleFromSlug } from "../../../utils/getTitleFromSlug";
import { updateObjectPage, updateOrigin } from "../../../pulse";
import { trackViewListPage } from "../../../pulse/event/trackListingPage";

type IsViewTopicCheck = {
  type: PageType;
  pathname: string;
  id?: string;
  runOnce: boolean;
};

type ViewTopic = {
  url: string;
  id?: string;
  type: PageType;
  searchParams: ReadonlyURLSearchParams;
};

const shouldViewTopicEvent = ({
  type,
  pathname,
  id,
  runOnce,
}: IsViewTopicCheck) =>
  type === "Listing" && isTopicPage(pathname, id) && runOnce;

const viewTopic = ({ url, id, type, searchParams }: ViewTopic) => {
  if (id) {
    const splittedUrl = url.split("/");
    const lengthOfSplittedUrl = splittedUrl.length;
    const topicNameSlug = splittedUrl.slice(
      lengthOfSplittedUrl - 2,
      lengthOfSplittedUrl - 1,
    )[0];

    const topicName = getTitleFromSlug(topicNameSlug);

    updateObjectPage({
      id,
      type,
      url: url,
      name: topicName,
    });

    trackViewListPage(id, topicName!);

    updateOrigin({
      id: id,
      type,
      url,
      name: topicName,
      source: searchParams.get("utm_source") || undefined,
      channel:
        searchParams.get("utm_medium") ||
        searchParams.get("utm_channel") ||
        undefined,
      content: searchParams.get("utm_content") || undefined,
      campaign: searchParams.get("utm_campaign") || undefined,
      terms: searchParams.get("utm_terms") || undefined,
    });
  }
};

export { viewTopic, shouldViewTopicEvent };
